import React from 'react'
import Layout from '../components/layout'
import '../styles/community.scss'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import { Container, Row, Col } from 'reactstrap'
import FindADentist from '../components/find-dentist'

const Community = () => {
  return (
    <Layout>
      <SEO
        title="Lumineers LifeStyle | Lumineers Smile Transformation Community"
        description="The Lumineers Lifestyle. Live your life knowing your outward appearance matches your inner beauty. Join the Lumineers Smile Transformation Community."
      />
      <Container>
        <Row>
          <Col md="6">
            <div className="lifestyle-container mt-5 mb-5 align-items-center">
              <h1 className="mb-5 community-title" style={{ fontSize: `3rem` }}>
                The Lumineers<sup>&reg;</sup> <br />
                Lifestyle
              </h1>
              <p>
                A confident enthusiastic smile can make a significant impact on
                how you see yourself as well as how others perceive you. In
                fact, a survey from the American Academy of Cosmetic Dentistry
                revealed that nearly half of adults (48%) believe that a smile
                is the most memorable feature after first meeting someone.
                <sup>1</sup> Live your life knowing your outward appearance
                matches your inner beauty. That’s the Lumineers<sup>&reg;</sup>{' '}
                Lifestyle.
              </p>
            </div>
          </Col>
          <Col md="6">
            <div className="intro-container text-center mt-5 mb-5">
              <StaticImage
                src="../images/Group-Lumineers-800x800.jpg"
                alt="Group photo"
                placeholder="none"
                layout="fullWidth"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="youtube-container">
        <Container>
          <Row>
            <Col md="12">
              <div className="mb-5 mt-5">
                <StaticImage
                  src="../images/Woman-Boat-Lumineers-1200x400.jpg"
                  alt="Woman on boat"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-5 text-center">
                  Join the Lumineers Community.
                </h2>
                <p>
                  The Lumineers Community celebrates beautiful life-changing
                  smile transformations. Join our social media community where
                  we share personal experiences and real stories of people just
                  like you.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md="6">
              <div className="d-flex justify-content-center social-image">
                <a
                  href="https://www.facebook.com/LumineersVeneers/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../images/facebook-lg.png"
                    alt="Woman on boat"
                    placeholder="none"
                    layout="fixed"
                    width={128}
                    height={128}
                  />
                </a>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex justify-content-center social-image">
                <a
                  href="https://www.instagram.com/lumineersveneers/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../images/instagram-lg.png"
                    alt="Woman on boat"
                    placeholder="none"
                    layout="fixed"
                    width={128}
                    height={128}
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="">
        <Row>
          <Col md="12">
            <div className="lifestyle-container">
              <h2 className="mt-5">
                #LumineersLife #SmileDesign #Lumineers #LumineersVeneers
                #SmileTransformation
              </h2>
              <p>Inspiring stories and beautiful smile transformations</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col md="3">
            <div className="mt-5 mb-3 text-center">
              <StaticImage
                src="../images/Woman-Laugh-Lumineers-800x800.jpg"
                alt="Woman laughing"
                placeholder="none"
                layout="fullWidth"
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mt-5 mb-3 text-center">
              <StaticImage
                src="../images/Woman-Dog-Lumineers-800x800.jpg"
                alt="Woman with dog"
                placeholder="none"
                layout="fullWidth"
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mt-5 mb-3 text-center">
              <StaticImage
                src="../images/Man-Mature-Lumineers-800x800.jpg"
                alt="Man smiling"
                placeholder="none"
                layout="fullWidth"
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mt-5 mb-3 text-center">
              <StaticImage
                src="../images/Woman-Rain-Lumineers-800x800.jpg"
                alt="Woman in rain"
                placeholder="none"
                layout="fullWidth"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-5">
        <Row>
          <Col md="12">
            <p className="text-center small">
              1. Kelton Global for the AACD{' '}
              <a
                target="_blank"
                href="https://aacd.com/proxy/files/Publications%20and%20Resources/AACD_First_Impressions.pdf"
                rel="noreferrer"
              >
                https://aacd.com/proxy/files/Publications%20and%20Resources/AACD_First_Impressions.pdf
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <FindADentist></FindADentist>
    </Layout>
  )
}

export default Community
